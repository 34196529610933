import React from "react";
import BottomNavbar from "../components/BottomNavbar";
import styled from "styled-components";

const TitleWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 900;
    text-align: center;
    justify-content: center;
`

const AboutTitle = styled.p`
font-size: 6rem;
color: white;
@media only screen and (max-width: 600px) {
    font-size: 3rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 6rem;
  }
`

const AboutSubtitle = styled.p`
  font-size: 1rem;
  color: red;
`
export default function About() {
    return (
        <div className="container-fluid h-100 d-flex flex-column">
            <TitleWrapper>
                <AboutTitle>
                I GET SHIT DONE.
                </AboutTitle>
                <AboutSubtitle>
                that’s everything you need to know
                </AboutSubtitle>
                <AboutSubtitle>
                (in every case <a className="link" style={{color: 'red', fontStyle: "italic"}} href="./selver cv.pdf" target="blank">here</a> is my CV)
                </AboutSubtitle>
            </TitleWrapper>
            <BottomNavbar></BottomNavbar>
        </div>
    );
}