import React from "react";
import styled from "styled-components";
import BottomNavbar from "../../components/BottomNavbar";

const MainSlideContainer = styled.div`
  background: black;
  height: 100%;
  width: 100%;
`;

const MainSlideTitle = styled.p`
  font-size: 6rem;
  color: white;
  font-weight: 900;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 3rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 6rem;
  }
`;

const MainSlideSubtitle = styled.p`
  color: white;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: left;
`;

const MainSlideCreative = styled.p`
  color: #f13636;
  font-size: 1rem;
  font-weight: 700;
  font-style: italic;
  text-align: left;
`;
export default function MainSlide() {
  return (
    <>
      <MainSlideContainer>
        <div className="container-fluid h-100">
          <div className="d-flex flex-column justify-content-end h-100">
            <div className="d-flex flex-column">
              <MainSlideTitle>
                I GET SHIT
                <br></br>DONE.
              </MainSlideTitle>
              <MainSlideSubtitle>Selver Učanbarlić</MainSlideSubtitle>
              <MainSlideCreative>Creative strategist</MainSlideCreative>
            </div>
          </div>
        </div>
      </MainSlideContainer>
    </>
  );
}
