import React from "react";
import BottomNavbar from "../components/BottomNavbar";
import styled from "styled-components";

const ContactTextWrapper  = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
`

const ContactTitle = styled.p`
    font-size: 2rem;
    color: red;
    font-weight: 900;
    text-decoration: none;
`

export default function Contact() {
    return (
        <div className="container-fluid h-100 d-flex flex-column">
            <ContactTextWrapper>
                <ContactTitle>
                    <a href="mailto:u.selver@gmail.com" style={{textDecoration: 'none', color: 'red'}}>
                    u.selver@gmail.com
                    </a>
                </ContactTitle>
            </ContactTextWrapper>
            <BottomNavbar></BottomNavbar>
        </div>
    );
}