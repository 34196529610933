import React from "react";
import styled from "styled-components";


const GenericSlideContainer = styled.div`
  background: black;
  height: 100%;
  width: 100%;
`;

const GenericSlideTitle = styled.p`
  font-size: 6rem;
  color: white;
  font-weight: 900;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 3rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 6rem;
  }
`;

export default function GenericSlide({subject}) {
    return (
    <GenericSlideContainer>
        <div className="w-80 h-100">
          <div className="d-flex flex-column justify-content-end h-100">
            <div className="d-flex flex-column">
              <GenericSlideTitle>
                I GET {subject}
                <br></br>DONE.
              </GenericSlideTitle>
            </div>
          </div>
        </div>
      </GenericSlideContainer>
    );
}