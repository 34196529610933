import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useState } from "react";
import "./portofolio.css";
import BottomNavbar from "../components/BottomNavbar";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


const PortofolioLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    column-gap: 1rem;
    row-gap: 1rem;
    justify-content: center;
`

const items = [
    {
        title: "3D modeling for private client",
        imageUrl: "../portofolio/3D modeling for private client.png",
        size: "small"
    },
    {
        title: "Arhitecture design for private client II",
        imageUrl: "../portofolio/Architecture-design-for-private-client-II.png",
        size: "medium"
    },
    {
        title: "Arhitecture design for private client",
        imageUrl: "../portofolio/Architecture-design-for-private-client.png",
        size: "large"
    },
    {
        title: "Concept design (parody)",
        imageUrl: "../portofolio/Concept-design-(parody).png",
        size: "small"
    },
    {
        title: "Concept design for private client",
        imageUrl: "../portofolio/Concept-design-for-private-client.png",
        size: "small"
    },
    {
        title: "Creative director of Mahala Magazine II",
        imageUrl: "../portofolio/Creative director of Mahala Magazine II.png",
        size: "large"
    },
    {
        title: "Creative director of Mahala Magazine",
        imageUrl: "../portofolio/Creative director of Mahala Magazine.webp",
        size: "medium"
    },
    {
        title: "Creative director for Partyzan Clothing Brand",
        imageUrl: "../portofolio/Design for Partyzan Clothing Brand.jpg",
        size: "medium"
    },
    {
        title: "Design for Partyzan Clothing II",
        imageUrl: "../portofolio/Design for Partyzan Clothing II.jpg",
        size: "small"
    },
    {
        title: "Design for Partyzan Clothing",
        imageUrl: "../portofolio/Design for Partyzan Clothing Brand.jpg",
        size: "large"
    },
    {
        title: "Digital art for private client",
        imageUrl: "../portofolio/Digital art for private client.jpeg",
        size: "large"
    },
    {
        title: "Exhibition design for KUMA International II",
        imageUrl: "../portofolio/Exhibition-design-for-KUMA-International-II.png",
        size: "medium"
    },
    {
        title: "Magazine design for the third issue of Mahala Magazine",
        imageUrl: "../portofolio/Magazine design for the third issue of Mahala Magazine.png",
        size: "large"
    },
    {
        title: "Model photography",
        imageUrl: "../portofolio/Model photography.jpg",
        size: "small"
    },
    {
        title: "Monument design (competition. 2nd place)",
        imageUrl: "../portofolio/Monument-design-(competition,2nd-place).png",
        size: "medium"
    },
    {
        title: "Music video production and filming for Merita Moric",
        imageUrl: "../portofolio/Music video production and filming for Merita Moric.png",
        size: "large"
    },
    {
        title: "Product photography for Comfy Candles",
        imageUrl: "../portofolio/Product photography for Comfy Candles.jpg",
        size: "small"
    },
    {
        title: "Production Design for the UNSAFE GORAZDE exhibition",
        imageUrl: "../portofolio/Production Design for the UNSAFE GORAZDE exhibition.png",
        size: "medium"
    },
    {
        title: "Race for Planet Earth, Total design campaing for AARHUS CENTRE",
        imageUrl: "../portofolio/Race for Planet Earth, Total design campaing for AARHUS CENTRE.png",
        size: "small"
    },
    {
        title: "Sarajevo postcard for Mahala Magazine",
        imageUrl: "../portofolio/Sarajevo-postcard-for-Mahala-Magazine.png",
        size: "large"
    },
    {
        title: "Sketches for Studio Regime",
        imageUrl: "../portofolio/Sketches for Studio Regime.jpg",
        size: "medium"
    },
    {
        title: "Social media managment for WARM FESTIVAL 2022",
        imageUrl: "../portofolio/Social media managment for WARM FESTIVAL 2022.png",
        size: "small"
    },
    {
        title: "Sound Design for Sevdah Tone exhibition by Mateas Pares",
        imageUrl: "../portofolio/Sound Design for Sevdah Tone exhibition by Mateas Pares.jpeg",
        size: "large"
    },
    {
        title: "Tehnical solution for exhibition",
        imageUrl: "../portofolio/Tehnical solution for exhibition.jpg ",
        size: "medium"
    },
    {
        title: "Totebag design for KUMA International",
        imageUrl: "../portofolio/Totebag design for KUMA International.jpg",
        size: "small"
    },
    {
        title: "Urban design of the Liberation Square in Sarajevo (competition)",
        imageUrl: "../portofolio/Urban-design-of-the-Liberation-Square-in-Sarajevo-(competition).png",
        size: "small"
    },
    {
        title: "Visual design for docuMfest Zenica",
        imageUrl: "../portofolio/Visual design for docuMfest Zenica.jpg",
        size: "medium"
    },
    {
        title: "Visual design for KVIRHANA FEST",
        imageUrl: "../portofolio/Visual design for KVIRHANA FEST(competition).jpg",
        size: "medium"
    },
    {
        title: "Visual design for Modernist Utopia project",
        imageUrl: "../portofolio/Visual design for Modernist Utopia project.png",
        size: "medium"
    },
    {
        title: "Visual for Ljetne Veceri Studio Teatar",
        imageUrl: "../portofolio/Visual-for-Ljetne-Veceri-Studio-Teatar.png",
        size: "small"
    },


];

const PortofolioHeader = styled.p`
    color: white;
    font-size: 8rem;
    font-weight: 900;

    @media only screen and (max-width: 600px) {
        font-size: 3rem;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        font-size: 5rem;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {...}
`

export default function Portofolio() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    return (
        <div className="container">
            <div className="text-center m-1">
                <PortofolioHeader>
                    PORTOFOLIO
                </PortofolioHeader>
            </div>
            <PortofolioLayout>
                {
                    items.map((item, index) => {
                        return <PortofolioItem 
                        size={item.size} 
                        title={item.title} 
                        image={item.imageUrl}
                        onClick= { () => {
                            console.log("Test")
                            setPhotoIndex(index);
                            setIsOpen(true);
                        }
                        }
                        />
                    })
                }
            </PortofolioLayout>
            <BottomNavbar></BottomNavbar>
            {isOpen &&
                <Lightbox
                    mainSrc={items[photoIndex].imageUrl}
                    nextSrc={items[(photoIndex + 1) % items.length].imageUrl}
                    prevSrc={items[(photoIndex + items.length - 1) % items.length].imageUrl}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + items.length - 1) % items.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % items.length)
                    }
                    imageCaption={items[photoIndex].title.toUpperCase()}
                    className="text-center"
                    style={{textAlign: "center"}}
                />
            }
        </div>
    );
}

const PortofolioItemTytle = styled.div`
    color: white;
    text-align: center;
    position: absolute;
    width: 100%; 
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
`

const PortofolioTitle = styled.p`
    color: white;
    font-size: 2rem;
    position: absolute;
    bottom: 0;
    font-weight: 900;
    opacity: 0.6;

`

function PortofolioItem({ title, description, image, size, onClick }) {
    let realTitle = title.toUpperCase()
    return (
        <motion.div
            className={`${size}`}
            style={{ position: 'relative', cursor: 'pointer' }}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            onClick={onClick}
        >
            <motion.img src={image} style={{ width: '100%', height: '100%', borderRadius: '1rem', objectFit: 'cover' }}>
            </motion.img>


            <PortofolioItemTytle>
                <PortofolioTitle>{realTitle}</PortofolioTitle>
            </PortofolioItemTytle>

        </motion.div>
    );
}