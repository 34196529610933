import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../styles.css"
import { IoClose } from "react-icons/io5"





const MobileMenu = {
    height: '100%',
    position: 'fixed',
    right: 0,
    top: 0,
    background: 'white',
    zIndex: 3,
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center"
}

const CloseButtonStyle = {
    fontSize: '3rem',
    position: 'fixed',
    top: 10,
    left: 10,
    cursor: 'pointer'
}

export default function BottomNavbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className="d-flex flex-row justify-content-end w-100 p-5 d-none d-lg-flex" style={{ height: '10%' }}>
                <NavbarItem title="Home" link="/"/>
                <NavbarItem title="Portofolio" link="/portofolio"></NavbarItem>
                <NavbarItem title="About me" link="/about"></NavbarItem>
               {/* <NavbarItem title="Blog"></NavbarItem> */}
                <NavbarItem title="Contact me" link={"/contact"}></NavbarItem>
            </div>
            < div className="flex-row justify-content-end w-100 p-5 d-flex d-lg-none">
                <FiMenu style={{ color: 'white', fontSize: '2rem', cursor: 'pointer' }}
                    onClick={() => { setIsOpen(true) }}
                ></FiMenu>
            </div>
            <AnimatePresence>
            {
                isOpen &&
                <motion.div
                initial={{width: '0%'}}
                animate={{width: '100%'}}
                exit={{width: '0%'}}
                style={MobileMenu}
                >
                    <IoClose style={CloseButtonStyle} onClick={() => {setIsOpen(false) }}></IoClose>

                    <MobileNavbarItem title="Home" link="/"/>
                    <MobileNavbarItem title="Portofolio" link="/portofolio"/>
                    <MobileNavbarItem title="About" link="/about"/>
                    {/* <MobileNavbarItem title="Blog"/> */}
                    <MobileNavbarItem title="Contact me" link="/contact"/>
                </motion.div>
            }
            </AnimatePresence>
        </>
    );
}

let NavbarItemStyle = styled.p`
    color: white;
    font-weight: 900;
    font-size: 1rem;
`

function NavbarItem({ title, link }) {
    return (
        <Link to={link}  className="link">
            <div className="d-flex justify-content-center align-items-center p-2">
                <NavbarItemStyle>
                    {title}
                </NavbarItemStyle>
            </div>
        </Link>
    );
}

const MobileNavbarItemStyle = styled.div`
    color: black;
    font-weight: 900;
    font-size: 2rem;
    text-decoration: none;
`

function MobileNavbarItem({title, link}) {
    return (
        <Link to={link} className="link">
            <MobileNavbarItemStyle>
                {title.toUpperCase()}
            </MobileNavbarItemStyle>
        </Link>
    )
}