import { Routes, Route, Link } from "react-router-dom";
import About from "./screens/About";
import Home from "./screens/Home";
// eslint-disable-next-line
import "swiper/css/bundle";
import "./styles.css";
import Portofolio from "./screens/Portofolio";
import Contact from "./screens/Contact";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portofolio" element={<Portofolio/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
    </div>
  );
}

export default App;
