import React, { useRef, useState } from "react";
import { Mousewheel, Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "../styles.css";
import MainSlide from "./Slides/MainSlide";
import GenericSlide from "./Slides/GenericSlide";
import BottomNavbar from "../components/BottomNavbar";

export default function Home() {
  return (
    <>
    <div className="d-flex flex-column h-100">
      <Swiper
        direction={"vertical"}
        modules={[Mousewheel, Pagination, Autoplay]}
        pagination={{ clickable: true }}
        mousewheel={true}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        autoplay={
          { 
            delay: 2000,
            disableOnInteraction: true,
           }
        }
        loop={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <MainSlide></MainSlide>
        </SwiperSlide>
        <SwiperSlide>
          <GenericSlide subject="ARCHITECTURE"></GenericSlide>
        </SwiperSlide>
        <SwiperSlide>
          <GenericSlide subject="PHOTOGRAPHY" />
        </SwiperSlide>
        <SwiperSlide>
          <GenericSlide subject="DESIGN" />
        </SwiperSlide>
        <SwiperSlide>
          <GenericSlide subject="PRODUCTION" />
        </SwiperSlide>
      </Swiper>
      <BottomNavbar></BottomNavbar>
      </div>
    </>
  );
}
